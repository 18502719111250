/* global Coveo */

document.addEventListener("DOMContentLoaded", function () {
    fetch("https://hyppimpgupvx2ofcrsn2yxsg2i0dcmgs.lambda-url.us-east-1.on.aws/")
        .then(response => {
            if (!response.ok) {
                throw new Error("Failed to fetch API key");
            }
            return response.json();
        })
        .then(responseData => {
            const PUBLIC_API_KEY = responseData;

            const searchbox = document.querySelector("#searchbox");
            const search = document.querySelector("#search");

            Coveo.SearchEndpoint.configureCloudV2Endpoint(
                "",
                PUBLIC_API_KEY,
                "https://platform.cloud.coveo.com/rest/search",
                {
                    queryStringArguments: {
                        searchHub: "CommunitySearch",
                    },
                }
            );

            if (search) {
                search.addEventListener("newResultDisplayed", hideShowMoreLink);
                Coveo.init(search, {
                    externalComponents: [searchbox],
                });
            } else {
                let searchPageURI = "https://success.qualys.com/support/s/global-search/%40uri";
                let options = {
                    Omnibox: {
                        placeholder: "Search",
                    },
                };

                if (location.pathname.match(/^\/vulnerability-detection-pipeline/)) {
                    searchPageURI = "https://success.qualys.com/support/s/global-search/%40uri";
                    options.Omnibox.placeholder = "Search Discussions, Blog Posts, Training, Docs and Support";
                }

                if (location.pathname.match(/^\/documentation/)) {
                    searchPageURI = "https://success.qualys.com/support/s/global-search/%40uri#t=Docs";
                    options.Omnibox.placeholder = "Search documentation";
                }

                if (location.pathname.match(/^\/training/)) {
                    searchPageURI = "https://success.qualys.com/support/s/global-search/%40uri#t=Training";
                    options.Omnibox.placeholder = "Search training";
                }

                Coveo.initSearchbox(searchbox, searchPageURI, options);
            }
        })
        .catch(error => {
            console.error(error);
            // Handle error
        });
});

function hideShowMoreLink(event) {
    const result = event.detail.result;
    const item = event.detail.item;
    const totalNumberOfChildResults = result.raw.jivereplycount || result.raw.wpcommentcount || 0;
    const numberOfChildResultsShown = result.childResults.length;
    const numberOfChildResultsRemaining = totalNumberOfChildResults - numberOfChildResultsShown;

    if (numberOfChildResultsRemaining < 1) {
        const coveoFoldingFooter = item.querySelector(".coveo-folding-footer");
        if (coveoFoldingFooter) {
            coveoFoldingFooter.classList.add("hidden");
        }
    }
}
